.Panel_p1mc7xnp{position:relative;width:100%;background-color:#EDEDED;min-height:30px;color:#474952;background-color:#ffffff;border-bottom-right-radius:12px;border-bottom-left-radius:12px;page-break-before:avoid;page-break-after:avoid;page-break-inside:avoid;}
@media (max-width:768px){.TopPanel_ttpwfd0.Panel_p1mc7xnp{display:none;}}
.ContentPanel_c1nxu0wh.Panel_p1mc7xnp{border-bottom-left-radius:10px;border-bottom-right-radius:10px;}@media (max-width:768px){.ContentPanel_c1nxu0wh.Panel_p1mc7xnp{overflow:hidden;}}
.Container_c1eyaqo2{min-height:600px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}@media (max-width:768px){.Container_c1eyaqo2{min-height:0;}}
.Divider_d9agkks{margin:0;background-color:#EDEDED;}
.LogoContainer_l1q60v25{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:28px;height:28px;background-color:#ffffff;border-radius:50%;}.LogoContainer_l1q60v25 svg{width:13px;height:16px;color:#000000;}
.DisclaimerButton_da2n74o{background:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-left:6px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-style:none;color:#19a49b;}.DisclaimerButton_da2n74o:focus{outline:none;}
.InformationIcon_i11x6lkq{height:14px;width:14px;margin-left:6px;}.InformationIcon_i11x6lkq path{fill:#19a49b;}
.DisclaimerContainer_d1urihxa{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-feature-settings:"clig" off,"liga" off;font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.875rem;line-height:0.875rem;}.DisclaimerContainer_d1urihxa > strong{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.EducationModalButton_e1d6uffd.DisclaimerButton_da2n74o{color:#7439B3;}
.Banner_b1ux8h9k{background-color:#EDEDED;color:#474952;-webkit-letter-spacing:-0.49px;-moz-letter-spacing:-0.49px;-ms-letter-spacing:-0.49px;letter-spacing:-0.49px;box-shadow:0px 1px 1px 0px rgba(#000000.25);-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.75rem;padding:6px 12px;height:36px;font-size:0.875rem;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}.Banner_b1ux8h9k > span{display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:horizontal;}
.BannerTextContainer_boggtwc{-webkit-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.75rem;}
.BannerCta_b1m60doa{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.75rem;}
.Burger_byvt4hg{position:absolute;left:10px;cursor:pointer;display:none;}@media (max-width:768px){.Burger_byvt4hg{display:block;}}
